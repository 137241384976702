body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Nunito', 'Segoe UI', 'Roboto', 'Oxygen',*/
  /*  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',*/
  /*  sans-serif;*/
  /*font-family: Bahnschrift, "Roboto Light, sans-serif", serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  /*font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
  /*  monospace;*/
}

.nav-dropdown-remove-arrow {
  font-size: 2em;
  color:white;
}

.nav-dropdown-remove-arrow > .dropdown-toggle::after {
  visibility: hidden;
  content: none !important;
}


@font-face {
  font-family: 'Nunito';
  src: local('Nunito'), url(../public/fonts/Nunito/Nunito-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway';
  src: local('Raleway'), url(../public/fonts/Raleway/Raleway-Thin.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'Raleway-Light';
  src: local('Raleway-Light'), url(../public/fonts/Raleway/Raleway-Light.ttf) format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}