.App {
  text-align: center;
  font-family: "Raleway", serif;
  width: 100%;
}

#main_bg {
  background-image: url('michalIntro.png');
  background-size: auto 100%;
  background-position: 55% bottom;
  background-repeat: no-repeat;
  background-color: #020023;
}

#main_bg2 {
  background-color: #020023;
}

.main-rows {
  background-color: #020023;
  color: white;
  display: flex;
}

.main-cols {
  background-color: #020023;
  color: white;
  display: flex;
  flex-direction: column;
}

.main-rows h1 {
  font-family: "Raleway-Light", serif;
  font-size: 3em;
}

.main-header {
  position: absolute;
  top: 40vh;
  font-size: 7em;
  margin-left: 50%;
  color: #ffffff;
  letter-spacing: -0.7vw;
  font-family: "Raleway-Light", serif;
  line-height: 1;
  text-align: left;
  elevation: higher;
  text-shadow: 2px 2px 2px black;
}

.main-header-prop {
  display: inline;
  vertical-align: middle;
  color: #ffffff;
  background-color: #020023;
  letter-spacing: 0vw;
  font-family: "Raleway-Light", serif;
  line-height: 1;
  text-align: left;
  padding: 10px;
  margin-top: 100px;
}

.main-sub-header {
  width: 85%;
  margin:0 auto;
  font-size: 2em;
  color: white;
  font-family: "Nunito", serif;
  text-decoration: underline;
  text-decoration-color: #214a9e;
  text-underline-offset: 9px;
  line-height: 2;
  text-decoration-thickness: 3px;
}

.underline {
  background-image: linear-gradient(#214a9e, #214a9e);
  background-position: bottom center;
  background-size: 65% 3px;
  background-repeat: no-repeat;
  padding-bottom: 10px;
}

.underline-short {
  background-image: linear-gradient(#214a9e, #214a9e);
  background-position: bottom center;
  background-size: 20% 3px;
  background-repeat: no-repeat;
  padding-bottom: 20px;
  margin-bottom: 40px;
}
.offer-container {
  padding: 12px 16px;
  height: 85px;
}
.off-r {
  border-right: 2px solid #214a9e;
}


.offer-container p {
  display: block;
  padding: 10px;
  font-size: 1.2em;
}

.d_container {
  width: 100%;
  background-color: #020023;
}

li {
  font-size: 1.2em;
}
li::marker {
  color: #214a9e;
}

.deadlift-image {
  width: 750px;
  margin: 0 auto;
}

.trainer-image {
  width: 750px;
}

.why_span {
  /*color: #214a9e;*/
  /*text-decoration: underline;*/
  /*text-decoration-color: #214a9e;*/
  /*text-underline-offset: 6px;*/
  /*text-decoration-thickness: 3px;*/

  font-weight: bold;
}

.priceBanner {
  background-color: #020023;
  padding: 24px 48px;
  margin: 2%;
}

.contactForm {
  height: 100%;
  margin:0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contactForm input {
  width: 250px;
  padding: 12px 20px;
  margin: 10px auto;
  box-sizing: border-box;
  border: 2px solid #214a9e;
  border-radius: 4px;
  background-color: white;
  color: black;
}

.contactForm textarea {
  width: 75%;
  height: 150px;
  padding: 12px 20px;
  margin: 10px auto;
  box-sizing: border-box;
  border: 2px solid #214a9e;
  border-radius: 4px;
  background-color: white;
  color: black;
  resize: none;
}

.contactForm button {
  display: block;
  padding: 12px 20px;
  margin: 0 auto;
  box-sizing: border-box;
  border: 2px solid #214a9e;
  background-color: #214a9e;
  color: white;
  resize: vertical;
}

.cert-download-button {
  text-decoration: none;
  color: white;

}


@media only screen and (max-width: 1332px) {
  .main-header {
    top: 40vh;
    margin-left: 50%;
    font-size: 6em;
  }

  .deadlift-image {
    width: 750px;
  }

  .trainer-image {
    width: 750px;
  }
}

@media only screen and (max-width: 1258px) {
  .main-header {
    top: 40vh;
    margin-left: 55%;
    font-size: 6em;
  }

  .deadlift-image {
    width: 750px;
  }

  .trainer-image {
    width: 750px;
  }
}

@media only screen and (max-width: 1150px) {
  .main-header {
    top: 40vh;
    margin-left: 62%;
    font-size: 4em;
  }

  .deadlift-image {
    width: 750px;
  }

  .trainer-image {
    width: 750px;
  }

}

@media only screen and (max-width: 974px) {
  .main-header {
    top: 40vh;
    margin-left: 60%;
    font-size: 4em;
  }

  .deadlift-image {
    width: 650px;
  }

  .trainer-image {
    width: 650px;
  }
}


@media only screen and (max-width: 900px) {
  .main-header {
    top: 50vh;
    margin-left: 45%;
    font-size: 4em;
  }

  .deadlift-image {
    width: 650px;
  }

  .trainer-image {
    width: 650px;
  }
}

@media only screen and (max-width: 600px) {

  .main-header {
    top: 60vh;
    padding-left: 10%;
    font-size: 4em;
  }
  .main-header-prop {
    display: block;
  }
  .deadlift-image {
    width: 450px;
  }

  .trainer-image {
    width: 400px;
  }
}

@media only screen and (max-width: 450px) {

  .main-header {
    top: 60vh;
    font-size: 3.5em;
  }

  .main-header-prop {
    display: block;
    text-align: left;
    font-size: 0.4em;
    padding: 10px;
  }

  .deadlift-image {
    width: 350px;
  }

  .trainer-image {
    width: 350px;
  }
}